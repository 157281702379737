import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";

import BannerInterior from "../../components/BannerInterior";
import BannerAgenda from "../../components/BannerAgenda";
import CateringItem from "../../components/CateringItem";

import poster from "../../images/banner-postre.jpg";
import pic1 from "../../images/catering/postre/frangipane-con-frutos-rojos-1.jpg";
import pic2 from "../../images/catering/postre/molten-de-chocolate-con-helado-de-chocolate-blanco-1.jpg";
import pic3 from "../../images/catering/postre/pay-de-nuez-con-helado-de-vainilla-1.jpg";
import pic4 from "../../images/catering/postre/postre-2.jpg";
import pic5 from "../../images/catering/postre/1.jpg";
import pic6 from "../../images/catering/postre/2.jpg";
import pic7 from "../../images/catering/postre/3.jpg";
import pic8 from "../../images/catering/postre/4.jpg";

const platillos = [
	{
		img: pic1,
		titulo: "Frangipane con frutos rojos",
		descripcion: "",
	},
	{
		img: pic2,
		titulo: "Molten de chocolate con helado de chocolate blanco",
		descripcion: "",
	},
	{
		img: pic3,
		titulo: "Pay de nuez con helado de vainilla",
		descripcion: "",
	},
	{
		img: pic4,
		titulo: "Flan con frutos rojos",
		descripcion: "",
	},
	{
		img: pic5,
		titulo: "Deconstrucción Tiramisú",
		descripcion: "",
	},
	{
		img: pic6,
		titulo: "Tarta de platano macho caramelizado",
		descripcion: "",
	},
	{
		img: pic7,
		titulo: "Pay de Nuez con helado de vainilla",
		descripcion: "",
	},
	{
		img: pic8,
		titulo: "Bizcocho con mousse de pistache",
		descripcion: "",
	},
];

const Catering = () => (
	<Layout>
		<SEO title="Postre" />

		<BannerInterior
			small="Barcelona Catering"
			title="Postre"
			caption=""
			imgsrc={poster}
			textstyle="darks"
		/>

		<section className="platillos-blocks">
			<div className="container">
				<div className="row">
					{platillos.map((platillo, index) => (
						<div className="col-12 col-sm-6 col-md-4" key={index}>
							<CateringItem
								img={platillo.img}
								titulo={platillo.titulo}
								descripcion={platillo.descripcion}
								categoria="Postre"
							/>
						</div>
					))}
				</div>
			</div>
		</section>

		<BannerAgenda paddingtop="pt-7" />
	</Layout>
);

export default Catering;
